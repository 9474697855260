import React, { useEffect, useState } from 'react';
import {
    Container,
    Col,
    Row,
    Label,
    Input,
    Spinner, Alert

} from "reactstrap";
import { toast } from "react-toastify";
import getApi from "../../apis/get.api";

const Profile = () => {
    document.title = "Reseller | Account";
    const [profile, setProfile] = useState<any>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        getApi('/reseller/account/profile')
            .then((response) => {
                const data = response.data.data
                if(data){
                    setProfile(data);
                }
            })
            .catch((error) => {

                toast.error('There was an issue while fetching your account information');
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    return (
        <div className="page-content">
            <Container fluid={true}>
                <div className="rounded-3">
                    <h1 className="display-5 fw-bold">Account Information</h1>
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-header fs-4">
                                    In this page you will find all your account information
                                </div>
                                <div className="card-body">
                                    <Alert color="primary">
                                        <strong>To change any of your information, please contact your account manager</strong>
                                    </Alert>
                                    {!loading && profile && <Row className={"gap-3"}>
                                        <Col md={3} xs={12} className={""}>
                                            <Label for="fullnameInput" className="form-label">Reseller Name</Label>
                                            <Input type="text" className="form-control" id="fullnameInput" disabled={true} readOnly={true} placeholder="Enter your reseller name" value={profile.resellerName}/>
                                        </Col>
                                        <Col md={3} xs={12} className={""}>
                                            <Label for="inputEmail4" className="form-label">Reseller Email</Label>
                                            <Input type="email" className="form-control" id="inputEmail4" disabled={true} readOnly={true} placeholder="Email" value={profile.resellerEmail}/>
                                        </Col>
                                        <Col md={2} xs={12} className={""}>
                                            <Label for="inputEmail4" className="form-label">Reseller Country</Label>
                                            <Input type="email" className="form-control" id="inputEmail4" disabled={true} readOnly={true} placeholder="Email" value={profile.resellerCountry}/>
                                        </Col>
                                        <Col md={3} xs={12} className={""}>
                                            <Label for="inputAddress" className="form-label">Reseller Website Or Trader Name</Label>
                                            <Input type="text" className="form-control" id="inputAddress" disabled={true} readOnly={true} value={profile.resellerWebsiteUrl} placeholder="1234 Main St"/>
                                        </Col>
                                        <Col md={3} xs={12} className={""}>
                                            <Label for="resellerPhoneNumber" className="form-label">Reseller Phone Number</Label>
                                            <Input type="text" className="form-control" id="resellerPhoneNumber" disabled={true} readOnly={true} value={profile.resellerPhoneNumber} placeholder="Reseller Phone Number"/>
                                        </Col>
                                    </Row>}
                                    {loading && <Spinner/>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default Profile;