import React, {useEffect, useState} from 'react';
import {Container, Input, Label, Modal, Spinner, ModalHeader , ModalBody, ButtonGroup, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown} from "reactstrap";
import Select from "react-select";
import getApi from "../../../apis/get.api";
import postApi from "../../../apis/post.api";
import {toast} from "react-toastify";
import { getSystemCurrency } from 'common/commonFunction';

const CreateVoucher = () => {
    document.title = "Voucher | Create Voucher";
    const [merchants,setMerchants] = useState(null)
    const [vouchers,setVouchers] = useState([])
    const [disabledGenerateButton,setDisabledGenerateButton] = useState(false)
    const [merchant,setMerchant] = useState(null)
    const [amount,setAmount] = useState<string | number>(1)
    const [showVoucherModel, setShowVoucherModel] = useState<boolean>(false);
    const [loading,setLoading] = useState(false)
    const [generatedVoucherInfo,setGeneratedVoucherInfo] = useState<any>(null);
    const [vouchersPending,setVouchersPending] = useState<boolean>(false);
    const [quantity,setQuantity] = useState('')
    const [currency,setCurrency] = useState<any>(getSystemCurrency())

    useEffect(() => {
        getApi('/reseller/voucher/create').then((response) => {
            let merchantsList : any = [];
            response.data.data.merchants.forEach((merchant : any) => {
                merchantsList.push({
                    label : merchant.merchantName,
                    value : merchant.merchantId
                })
            })
            setMerchants(merchantsList)

            let vouchersData : any = [];
            response.data.data.vouchers.forEach((voucher : any) => {
                vouchersData.push({
                    voucherId : voucher.voucherId,
                    voucherCode : voucher.voucherCode,
                    voucherOrderId : voucher.voucherOrderId,
                    voucherAmount : voucher.voucherAmount,
                    voucherStatus : voucher.voucherStatus,
                    voucherExpiry : voucher.voucherExpiryDate,
                    voucherMerchantName : voucher.merchantName,
                    voucherCurrency : voucher.voucherCurrency
                })
            })
            setVouchers(vouchersData)
        }).catch((error) => {
            
        })
    },[])

    const handleAmount = (event: any) => {
        let amountValue: any = event.target.value;
        if (amountValue === '') {
            setAmount('');
        }
    
        // Use parseFloat to handle decimal values, but validate the input
        if (!isNaN(amountValue) && /^[0-9]*\.?[0-9]*$/.test(amountValue)) {
            setAmount(parseFloat(amountValue));
        }
    };


    const handleQuantity = (event : any) => {
        let quantityValue : any = parseInt(event.target.value);
        if(event.target.value === ''){
            quantityValue = '';
        }
        if(quantityValue <= 100){
            setQuantity(quantityValue);
        }
    }

    const handleMerchant = (event : any) => {
        setMerchant(event.value)
    }

    useEffect(() => {
        if(amount && quantity && merchant){
            setDisabledGenerateButton(false)
        }else{
            setDisabledGenerateButton(true)
        }
    }, [amount,merchant,quantity]);

    const generateVoucher = () => {
        setVouchersPending(false)
        setLoading(true)
        const payload = {
            merchantId : merchant,
            amount : amount,
            quantity : quantity,
            targetCurrency : currency
        };
        postApi('/reseller/voucher/create',payload).then((response) => {
            if(response.data.data.length > 0){
                setGeneratedVoucherInfo(response.data.data)
                setShowVoucherModel(true)
            }else{
                setVouchersPending(true)
            }
            toast.success('Voucher has been created successfully');
            setLoading(false)
        }).catch((error) => {
            let message : string = 'Something went wrong'
            if(error && error.response && error.response.data){
                message = error.response.data.message
            }
            toast.error(message)
            setShowVoucherModel(false)
            setLoading(false)
        })
    }

    function tog_center() {
        setShowVoucherModel(!showVoucherModel);
    }

    return <div className="page-content">
        <Container fluid={true}>
            <div className="rounded-3">
                <h1 className="display-5 fw-bold">Create voucher</h1>
                <p className="fs-4">
                    This page will allow you to create specific voucher form a wide range of merchants websites,
                    all what you have to do is pick the merchant, click Create Voucher button and you will have
                    the voucher ready
                </p>
                <div className="row mt-5 justify-content-center">
                    <div className="col-lg-8">
                        <div className="card">
                            <div className="card-header">
                                Latest 10 Generated Vouchers
                            </div>
                            <div className="table-responsive table-card card-body">
                                <table className="table align-middle table-nowrap table-striped-columns mb-0">
                                    <thead className="table-light">
                                    <tr>
                                        <th scope="col">Voucher ID</th>
                                        <th scope="col">Voucher Merchant</th>
                                        <th scope="col">Voucher Code</th>
                                        <th scope="col">Voucher Expiration</th>
                                        <th scope="col">Voucher Amount</th>
                                        <th scope="col">Voucher Currency</th>
                                        <th scope="col">Voucher Status</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        {vouchers && vouchers.length > 0 && vouchers.map((voucher : any,index : any) => {
                                            return <tr key={index}>
                                                <td><span className="fw-medium">#{voucher.voucherId}</span></td>
                                                <td>{voucher.voucherMerchantName}</td>
                                                <td>{voucher.voucherCode}</td>
                                                <td>{voucher.voucherExpiry}</td>
                                                <td>{voucher.voucherAmount}</td>
                                                <td>{voucher.voucherCurrency.toString().toUpperCase()}</td>
                                                <td>{voucher.voucherStatus.toString().toUpperCase()}</td>
                                            </tr>
                                        })}
                                        {vouchers && vouchers.length === 0 && <tr>
                                            <td className="text-center p-4" colSpan={6}>No Vouchers Created Yet</td>
                                        </tr>}
                                        {!vouchers && <tr>
                                            <td className="text-center p-4" colSpan={6}><Spinner/></td>
                                        </tr>}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="card">
                            <div className="card-header">
                                Generate Voucher Form
                            </div>
                            {merchants && <div className="card-body">
                                <div className="">
                                    <h6>Select Merchant To Generate Voucher For</h6>
                                    <Select
                                        options={merchants}
                                        id="choices-single-default"
                                        className="js-example-basic-single mb-0"
                                        name="merchant"
                                        onChange={handleMerchant}
                                    />
                                </div>
                                <div className="mt-2">
                                    <Label>Voucher Quantity</Label>
                                    <Input type="number" min={1} max={1000} value={quantity} onChange={handleQuantity}
                                           className="form-control" id="autoSizingInputGroup"
                                           placeholder="Quantity"/>
                                </div>
                                <div className="mt-2">
                                    <Label>Voucher Amount</Label>
                                    <div className="input-group">
                                        <ButtonGroup className="z-1">
                                            <UncontrolledButtonDropdown id="btnGroupDrop1">
                                                <DropdownToggle color="primary" caret size={'sm'}>
                                                    {currency}
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem onClick={() => {
                                                        setCurrency(getSystemCurrency())
                                                    }}> {getSystemCurrency()}</DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown>
                                        </ButtonGroup>
                                        <Input type="number" min={0.01} max={9999} value={amount} onChange={handleAmount}
                                               className="form-control" id="autoSizingInputGroup"
                                               placeholder="Amount"/>
                                    </div>
                                </div>
                                <div className={`mt-3 ${loading ? 'text-center' : ''}`}>
                                    {!loading && <button className="btn btn-primary w-100" onClick={generateVoucher}
                                                         disabled={disabledGenerateButton}>Generate Voucher</button>}
                                    {loading && <Spinner/>}
                                </div>
                            </div>}
                            {!merchants && <div className="text-center m-5"><Spinner/></div>}
                        </div>
                    </div>
                </div>
            </div>
            {generatedVoucherInfo && <Modal isOpen={showVoucherModel} size={'xl'} toggle={() => { tog_center(); }} centered>
                <ModalHeader className="modal-title"/>
                <ModalBody className="text-center">
                    <i className="ri-thumb-up-line display-5 text-success"></i>
                    <div className="mt-1">
                        <h4 className="mb-1">Voucher has been created successfully</h4>
                        <p className="text-muted mb-4"> A new vouchers has been created, please find the voucher
                            information down below</p>
                        <div className="table-responsive table-card mt-5">
                            <table className="table align-middle table-nowrap table-striped-columns mb-0">
                                <thead className="table-light">
                                <tr>
                                    <th scope="col">Voucher ID</th>
                                    <th scope="col">Voucher Order ID</th>
                                    <th scope="col">Merchant Name</th>
                                    <th scope="col">Reseller Name</th>
                                    <th scope="col">Voucher Code</th>
                                    <th scope="col">Voucher Amount</th>
                                    <th scope="col">Voucher Currency</th>
                                    <th scope="col">Voucher Expiration Date</th>

                                </tr>
                                </thead>
                                <tbody>
                                    {generatedVoucherInfo.map((voucher : any,index : any) => {
                                        return <tr key={index}>
                                            <td>#{voucher.voucherId}</td>
                                            <td>#{voucher.voucherOrderId}</td>
                                            <td>{voucher.merchantName}</td>
                                            <td>{voucher.resellerName}</td>
                                            <td>{voucher.voucherCode}</td>
                                            <td>{voucher.voucherAmount}</td>
                                            <td>{voucher.voucherCurrency}</td>
                                            <td>{voucher.voucherExpiryDate}</td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </ModalBody>
            </Modal>}
            {vouchersPending && <Modal isOpen={vouchersPending} size={'xl'} toggle={() => { setVouchersPending(false); }} centered>
                <ModalHeader className="modal-title"/>
                <ModalBody className="text-center">
                    <i className="ri-thumb-up-line display-5 text-success"></i>
                    <div className="mt-1">
                        <h4 className="mb-1">Voucher has been created successfully</h4>
                        <p className="text-muted mb-4"> You will find the created vouchers in your email 
                        </p>
                    </div>
                </ModalBody>
            </Modal>}
        </Container>
    </div>
}

export default CreateVoucher;