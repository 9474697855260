import React, {useEffect, useState} from 'react';
import { Card, CardBody, Col, Container, Input, Label, Row, Button, Form, FormFeedback, Alert, Spinner } from 'reactstrap';
import { Link, useLocation } from "react-router-dom";
import withRouter from "../../Components/Common/withRouter";
import postApi from 'apis/post.api';
import { ToastContainer, toast } from 'react-toastify';

const Login = (props : any) => {
    document.title = "ClvrPay.com | Reseller Login";
    const [userFormData,setUserFormData] = useState<any>({email : '',password : '',userType : "r"})
    const [error,setError] = useState<string>('')
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [loader, setLoader] = useState<boolean>(false);

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const redirectTo = searchParams.get('redirectTo');

    const changeInputHandler = (event : any) => {
        const updatedForm = { ...userFormData };
        updatedForm[event.target.name] = event.target.value;
        setUserFormData(updatedForm);
        validateFormInput(event);
    }

    const validateFormInput = (event : any) => {
        if(event.target.type == 'email'){
            if(event.target.value == ''){
                event.target.classList.add('is-invalid')
            }else{
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                if(emailRegex.test(event.target.value)){
                    event.target.classList.remove('is-invalid')
                    event.target.classList.add('is-valid')
                }else{
                    event.target.classList.add('is-invalid')
                }
            }
        }else if(event.target.type == 'password' || event.target.type == 'text'){
            if(event.target.value == ''){
                event.target.classList.add('is-invalid')
            }else{
                event.target.classList.remove('is-invalid')
                event.target.classList.add('is-valid')
            }
        }
    }

    useEffect(() => {
        const authUSer : any = localStorage.getItem("reseller_authUser");
        if (authUSer) {
            window.location.href = '/'
        }
    }, []);

    const loginUser = () => {
        setError('')
        setLoader(true)
        postApi('/reseller/login',userFormData).then((response) => {
            if(response.data.code === 10000){
                setLoader(false)
                localStorage.setItem("reseller_authUser", JSON.stringify(response.data.data.user));
                localStorage.setItem("reseller_access_token", response.data.data.access_token);
                if(redirectTo && redirectTo !== ''){
                    window.location.href= redirectTo ?? ""
                }else{
                    window.location.href= "/"
                }
            }else{
                setLoader(false)
                setError(response.data.error)
            }
        }).catch((error) => {
            setLoader(false)
            if(error.response.status == 422){
                setError(error.response.data.message)
            }else{
                toast.error('There was an error while authenticating your user. Please try again')
            }
        })
    }
    return (
        <React.Fragment>
            <div className="auth-page-content d-flex justify-content-center align-items-center min-vh-100">
                <Container>
                    <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="light"
                    />
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <Card className="mt-4">
                                <CardBody className="p-4">
                                <img alt="defualt" src={"https://clvrpay.com/static/media/logo.407eac689daf8d980d7c.png"} className="w-100"/>
                                <div className="p-2">
                                        {error && error ? (<Alert color="danger"> {error} </Alert>) : null}
                                        <Form
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                return false;
                                            }}
                                            action="#">
                                            <div className="mb-3">
                                                <Label htmlFor="email" className="form-label">Email</Label>
                                                <Input
                                                    name="email"
                                                    className="form-control"
                                                    placeholder="Enter email"
                                                    type="email"
                                                    onChange={changeInputHandler}
                                                    value={userFormData.email}
                                                />
                                                <FormFeedback type="invalid">Email must be a valid email
                                                    address</FormFeedback>
                                            </div>
                                            <div className="mb-3">
                                                <div className="float-end">
                                                    <Link to="/forgot-password" className="text-muted">Forgot
                                                        password?</Link>
                                                </div>
                                                <Label className="form-label"
                                                       htmlFor="password-input">Password</Label>
                                                <div className="position-relative auth-pass-inputgroup mb-3">
                                                    <Input
                                                        name="password"
                                                        value={userFormData.password}
                                                        type={showPassword ? "text" : "password"}
                                                        className="form-control pe-5"
                                                        placeholder="Enter Password"
                                                        onChange={changeInputHandler}
                                                    />
                                                    <FormFeedback type="invalid">Password is required</FormFeedback>
                                                    <button
                                                        className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted shadow-none"
                                                        onClick={() => setShowPassword(!showPassword)} type="button"
                                                        id="password-addon"><i
                                                        className="ri-eye-fill align-middle"></i></button>
                                                </div>
                                            </div>
                                            <div className="mt-4">
                                                <Button color="success"
                                                        disabled={loader && true}
                                                        onClick={loginUser}
                                                        className="btn btn-success w-100" type="submit">
                                                    {loader &&
                                                        <Spinner size="sm" className='me-2'> Loading... </Spinner>}
                                                    Sign In
                                                </Button>
                                            </div>
                                        </Form>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <div className="text-center">
                        <p className="mb-0">Don't have a reseller account? <Link to="/register" className="fw-semibold text-primary text-decoration-underline"> Create </Link> </p>
                    </div>
                </Container>
            </div>
        </React.Fragment>

    );
};

export default withRouter(Login);