import React from 'react';
import { Routes, Route} from "react-router-dom";
import NonAuthLayout from "../Layouts/NonAuthLayout";
import VerticalLayout from "../Layouts/index";
import AuthProtected from "./AuthProtected"
import { authProtectedRoutes, publicRoutes } from "./allRoutes";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Index = () => {
    const isMobileDevice = () => {
        if (typeof window !== 'undefined') {
            return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                window.navigator.userAgent || ""
            );
        }
        return false; // Return false if window is not defined (e.g., server-side rendering)
    }

    return (
        <React.Fragment>
            <ToastContainer
                className={`${isMobileDevice() ? 'w-100' : 'w-50'}`}
                rtl={false}
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                closeOnClick={true}
                pauseOnHover={true}
                draggable={true}
                theme="light"
            />
            <Routes>
                <Route>
                    {publicRoutes.map((route: { path: string | undefined; component: any; }, idx: React.Key | null | undefined) => (
                        <Route path={route.path}
                            element={
                                <NonAuthLayout>
                                    {route.component}
                                </NonAuthLayout>
                            }
                            key={idx}
                        />
                    ))}
                </Route>

                <Route>
                    {authProtectedRoutes.map((route, idx) => (
                        <Route
                            path={route.path}
                            element={
                                <AuthProtected>
                                    <VerticalLayout>{route.component}</VerticalLayout>
                                </AuthProtected>}
                            key={idx}
                            // exact={true}
                        />
                    ))}
                </Route>
            </Routes>
        </React.Fragment>
    );
};

export default Index;