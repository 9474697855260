import React, { useEffect, useState } from 'react';
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

const Currency = () => {
    const [currency, setCurrency] = useState<any>("USD");
    const [isCurrencyDropDown, setIsCurrencyDropDown] = useState<boolean>(false);

    const toggleChangeCurrency = () => {
        setIsCurrencyDropDown(!isCurrencyDropDown);
    };

    const changeCurrency = (currency : string) => {
        localStorage.setItem("currency", currency);
        setCurrency(currency)
        setTimeout(() => {
            window.location.reload();
        }, 300);
    };
    
    useEffect(() => {
        if(localStorage.getItem("currency")){
            setCurrency(localStorage.getItem("currency"))
        }
    },[])

    return (
        <React.Fragment>
            <Dropdown isOpen={isCurrencyDropDown} toggle={toggleChangeCurrency} className="ms-1 topbar-head-dropdown header-item">
                <DropdownToggle className="btn btn-icon  rounded" tag="button">
                    <Button color="light" className="position-relative avatar-sm rounded" style={{height : "32px"}}>
                        <span className="avatar-title bg-transparent text-reset">
                            {currency}
                        </span>
                    </Button>
                </DropdownToggle>
                <DropdownMenu className="notify-item language py-2">
                    <DropdownItem onClick={() => changeCurrency('USD')} >
                        USD
                    </DropdownItem>
                    <DropdownItem onClick={() => changeCurrency('MAD')} >
                        MAD
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
};

export default Currency;