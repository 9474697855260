import React, { useEffect, useState } from 'react';
import {
    Container,
    Spinner,
    Pagination,
    PaginationItem,
    PaginationLink,
} from "reactstrap";
import { toast } from "react-toastify";
import getApi from "../../apis/get.api";

const ListTransactions = () => {
    document.title = "Wallet | Account Statement";
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);

    const fetchVouchers = (page : number) => {
        setLoading(true);
        let url = `/reseller/wallet/transactions/list?page=${page}`;
        getApi(url)
            .then((response) => {
                const data = response.data.data;
                setTransactions(data.data);
                setCurrentPage(data.current_page);
                setLastPage(data.last_page);
            })
            .catch((error) => {
                toast.error('There was an issue while fetching the wallet transactions list');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchVouchers(currentPage);
    }, [currentPage]);

    const handlePageChange = (page : number) => {
        if (page !== currentPage) {
            setCurrentPage(page);
        }
    };

    return (
        <div className="page-content">
            <Container fluid={true}>
                <div className="rounded-3">
                    <h1 className="display-5 fw-bold">Account Statemenet</h1>
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-header fs-4">
                                    In this page you will find all wallet transactions that has been made on your account
                                </div>
                                <div className="card-body">
                                    <div className="table-responsive table-card ">
                                        <table className="table text-center align-middle table-nowrap table-striped-columns mb-0">
                                            <thead className="table-light">
                                                <tr >
                                                    <th scope="col" className={"text-start"}>Transaction Unique ID</th>
                                                    <th scope="col">Transaction ID</th>
                                                    <th scope="col">Transaction Amount</th>
                                                    <th scope="col">Transaction Currency</th>
                                                    <th scope="col">Transaction Type</th>
                                                    <th scope="col">Balance Before</th>
                                                    <th scope="col">Balance After</th>
                                                    <th scope="col">Transaction Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {loading && (
                                                <tr>
                                                    <td className="text-center p-4" colSpan={8}><Spinner /></td>
                                                </tr>
                                            )}
                                            {transactions && transactions.length > 0 && transactions.map((transaction : any) => (
                                                <tr key={transaction.transactionUnique} className={"fw-bold"}>
                                                    <td className={"text-start"}><span
                                                        className="fw-medium">#{transaction.transactionUnique}</span>
                                                    </td>
                                                    <td>{transaction.transactionId}</td>
                                                    <td className={`${transaction.transactionType === 'deduct' ? 'text-danger' : 'text-success'}`}>{transaction.transactionType === 'deduct' ? '-' : '+'}{transaction.transactionAmount}</td>
                                                    <td>{transaction.transactionCurrency.toString().toUpperCase()}</td>
                                                    <td>{transaction.transactionType.toString().toUpperCase()}</td>
                                                    <td>{transaction.transactionBalanceBefore}</td>
                                                    <td>{transaction.transactionBalanceAfter}</td>
                                                    <td>{transaction.transactionDate}</td>
                                                </tr>
                                            ))}
                                            {!loading && transactions.length === 0 && (
                                                <tr>
                                                    <td className="text-center p-4" colSpan={8}>No Transactions Created Yet</td>
                                                </tr>
                                            )}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="card-footer d-flex justify-content-center mt-4 border-top-0">
                                        <Pagination>
                                            <PaginationItem disabled={currentPage === 1}>
                                                <PaginationLink previous onClick={() => handlePageChange(currentPage - 1)} />
                                            </PaginationItem>
                                            {[...Array(lastPage)].map((_, i) => (
                                                <PaginationItem key={i + 1} active={currentPage === i + 1}>
                                                    <PaginationLink onClick={() => handlePageChange(i + 1)}>
                                                        {i + 1}
                                                    </PaginationLink>
                                                </PaginationItem>
                                            ))}
                                            <PaginationItem disabled={currentPage === lastPage}>
                                                <PaginationLink next onClick={() => handlePageChange(currentPage + 1)} />
                                            </PaginationItem>
                                        </Pagination>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default ListTransactions;