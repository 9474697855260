import React from 'react';
import './assets/scss/themes.scss';
import Route from './Routes';
import { Modal, ModalBody } from 'reactstrap';
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import LoginSubComp from 'pages/Authentication/LoginSubComp';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const isPopupOpenedSelector = createSelector(
    (state : any) => state.Popup.isPopupOpened,
    (isPopupOpened) => isPopupOpened
  );
  const popupNameSelector = createSelector(
    (state : any) => state.Popup.popupName,
    (popupName) => popupName
  );
  const isPopupOpened = useSelector(isPopupOpenedSelector);
  const popupName = useSelector(popupNameSelector);

  return (
    <React.Fragment>
      <Modal id="myModal" className="modal-dialog-centered p-0" isOpen={isPopupOpened} toggle={() => {}}>
        <ModalBody className='p-0'>
          {popupName == 'loginPopup' && <LoginSubComp/>}
        </ModalBody>
      </Modal>
      <Route />
    </React.Fragment>
  );
}

export default App;
