import React, {useEffect, useState} from 'react';
import {Card, CardBody, Col, Container, Row, Button, Alert} from 'reactstrap';
import getApi from "../../apis/get.api";
import {toast} from "react-toastify";
import putApi from "../../apis/put.api";
import Loader from "../../Components/Common/Loader";

const BasicTwosVerify = () => {
    document.title = "ClvrPay Email Verification";
    const [pinCode,setPinCode] = useState<any>(null);
    const [error,setError] = useState<string>()
    const [loading,setLoading] = useState<boolean>(false)
    const [disabledBtn,setDisabledBtn] = useState<boolean>(true)

    useEffect(() => {
        const authUSer : any = localStorage.getItem("reseller_authUser");
        if (authUSer) {
            const obj : any = JSON.parse(authUSer);
            if(obj.emailVerified){
                window.location.href = '/'
            }
        }
    }, []);


    const getInputElement = (index : any) : HTMLInputElement => {
        return document.getElementById('digit' + index + '-input') as HTMLInputElement;
    }

    const moveToNext = (index : any) => {
        if (getInputElement(index).value.length === 1) {
            if (index !== 6) {
                getInputElement(index + 1).focus();
            } else {
                getInputElement(index).blur();
            }
        }
        updatePinCode();
    }

    const updatePinCode = () => {
        let code = '';
        for (let i = 1; i <= 6; i++) {
            const digit = getInputElement(i).value;
            code += digit;
        }

        if(code.length == 6){
            setDisabledBtn(false);
        }else{
            setDisabledBtn(true);
        }
        setPinCode(code);
    }

    const resend = () => {
        getApi(`/reseller/email/verify/resend`).then((response) => {
            toast.success('Verification email has been sent to you, please check your inbox or check your spam folder')
        }).catch((error) => {
            toast.error('There was an issue while resend the verification email')
        })
    }

    const verify = () => {
        setLoading(true)
        setError('');
        putApi(`/reseller/email/verify/check/${pinCode}`,{}).then((response) => {
            toast.success(response.data.message);
            localStorage.setItem("reseller_authUser", JSON.stringify(response.data.data.user));
            localStorage.setItem("reseller_access_token", response.data.data.access_token);
            setTimeout(() => {
                window.location.href = '/';
            },5000)
        }).catch((error) => {
            // toast.error('There was an issue while resend the verification email')
            setError(error.response.data.message)
            setLoading(false)
        })
    }

    return (
        <React.Fragment>
            <div className="auth-page-wrapper">
                <div className="auth-page-content">
                        <Container>
                            <Row className="justify-content-center" style={{marginTop : "200px"}}>
                                <Col md={10} lg={8} xl={7}>
                                    <Card className="mt-4">
                                        <CardBody className="p-4">
                                            <div className="mb-4">
                                                <div className="avatar-lg mx-auto">
                                                    <div className="avatar-title bg-light text-primary display-5 rounded-circle">
                                                        <i className="ri-mail-line"></i>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="p-2 mt-4">
                                                <div className="text-muted text-center mb-4 mx-lg-3">
                                                    <h4>Verify Your Email</h4>
                                                    <p>Please enter the 6 digit code sent to <span className="fw-semibold">example@abc.com</span></p>
                                                </div>

                                                <form>
                                                    <Row>
                                                        <Col className="col-2">
                                                            <div className="mb-3">
                                                                <label htmlFor="digit1-input" className="visually-hidden">Digit 1</label>
                                                                <input type="text"
                                                                       className="form-control form-control-lg bg-light border-light text-center"
                                                                       maxLength={1}
                                                                       id="digit1-input" onKeyUp={() => moveToNext(1)} />
                                                            </div>
                                                        </Col>

                                                        <Col className="col-2">
                                                            <div className="mb-3">
                                                                <label htmlFor="digit2-input" className="visually-hidden">Digit 2</label>
                                                                <input type="text"
                                                                       className="form-control form-control-lg bg-light border-light text-center"
                                                                       maxLength={1}
                                                                       id="digit2-input" onKeyUp={() => moveToNext(2)} />
                                                            </div>
                                                        </Col>

                                                        <Col className="col-2">
                                                            <div className="mb-3">
                                                                <label htmlFor="digit3-input" className="visually-hidden">Digit 3</label>
                                                                <input type="text"
                                                                       className="form-control form-control-lg bg-light border-light text-center"
                                                                       maxLength={1}
                                                                       id="digit3-input" onKeyUp={() => moveToNext(3)} />
                                                            </div>
                                                        </Col>

                                                        <Col className="col-2">
                                                            <div className="mb-3">
                                                                <label htmlFor="digit4-input" className="visually-hidden">Digit 4</label>
                                                                <input type="text"
                                                                       className="form-control form-control-lg bg-light border-light text-center"
                                                                       maxLength={1}
                                                                       id="digit4-input" onKeyUp={() => moveToNext(4)} />
                                                            </div>
                                                        </Col>

                                                        <Col className="col-2">
                                                            <div className="mb-3">
                                                                <label htmlFor="digit5-input" className="visually-hidden">Digit 5</label>
                                                                <input type="text"
                                                                       className="form-control form-control-lg bg-light border-light text-center"
                                                                       maxLength={1}
                                                                       id="digit5-input" onKeyUp={() => moveToNext(5)} />
                                                            </div>
                                                        </Col>
                                                        <Col className="col-2">
                                                            <div className="mb-3">
                                                                <label htmlFor="digit6-input" className="visually-hidden">Digit 6</label>
                                                                <input type="text"
                                                                       className="form-control form-control-lg bg-light border-light text-center"
                                                                       maxLength={1}
                                                                       id="digit6-input" onKeyUp={() => moveToNext(6)} />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </form>
                                                {error && <Alert color="danger">
                                                    <strong> {error} </strong>
                                                </Alert>}
                                                <div className="mt-3">
                                                    {!loading && <Button disabled={disabledBtn} color="success" onClick={verify} className="w-100">Confirm</Button>}
                                                    {loading && <Loader/>}
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                    <div className="mt-4 text-center">
                                        <p className="mb-0">Didn't receive a code ? <span  onClick={resend} className="fw-semibold text-primary">Resend</span> </p>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
            </div>
        </React.Fragment >
    );
};

export default BasicTwosVerify;