import React, { useEffect, useState } from "react";
import {
    Row,
    Col,
    CardBody,
    Card,
    Alert,
    Container,
    Input,
    Label,
    Form,
    FormFeedback,
    Button,
    Spinner,
    CardHeader
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import getApi from "../../apis/get.api";
import Select from "react-select";
import IntlTelInput from "react-intl-tel-input";  // Import react-intl components
import "react-intl-tel-input/dist/main.css";
import postApi from "apis/post.api";

const Register = () => {
    const [countries, setCountries] = useState<any>(null);
    const [phoneError,setPhoneError] = useState<string>("");
    const [errorMsg,setErrorMsg] = useState<string | undefined>()
    const [success,setSuccess] = useState<boolean>(false)
    const [loader, setLoader] = useState<boolean>(false);

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: '',
            username: '',
            website: '',
            country_id: '',
            password: '',
            confirm_password: '',
            phone_number: '', // Ensure this is set to an empty string initially
            countryCode : "",
        },
        validationSchema: Yup.object({
            email: Yup.string().required("Please Enter Your Email"),
            username: Yup.string().required("Please Enter Your Username"),
            website: Yup.string().required("Please enter your website or trade name or social media link"),
            country_id: Yup.string().required("Please Select Your Country"),
            password: Yup.string().required("Please enter your password"),
            phone_number: Yup.string().required("Please enter company phone number"),
            confirm_password: Yup.string()
                .oneOf([Yup.ref("password")], "Passwords do not match")
                .required("Please confirm your password"),
        }),
        onSubmit: (values) => {
            setErrorMsg(undefined)
            setLoader(true)
            postApi('/reseller/register',values).then((response) => {
                if(response.data.code === 10000){
                    setSuccess(true)
                }else{
                    setErrorMsg(response.data.message)
                }
                setLoader(false)
            }).catch((error) => {
                setLoader(false)
                if(error && error.response && error.response.data && error.response.data){
                    setErrorMsg(error.response.data.message)
                }else{
                    setErrorMsg('Something went wrong while processing your request')
                }
                toast.error('Something went wrong while processing your request')
            })
        }
    });


    document.title = "ClvrPay.com | Create Reseller Account";

    useEffect(() => {
        getApi('/common/get/countries').then((response) => {
            const countriesList = response.data.data.map((country: any) => ({
                label: country.name,
                value: country.id,
            }));
            setCountries(countriesList);
        }).catch((error) => {
            toast.error('General error occurred');
            throw error;
        });
    }, []);

    const changeCountry = (element: any) => {
        validation.setFieldValue("country_id", element.value);
    };

    const handlePhoneChange = (isValid: boolean, value: string, country: any,fullNumber : string) => {
        validation.setFieldValue("phone_number", value); // Update Formik's value
        validation.setFieldValue("countryCode",country.dialCode);
        setPhoneError(!isValid ? 'Phone number is invalid, please check the provided phone number and try again' : '')
    };

    const handleCountryChange = (currentCountry: string, newCountry: any) => {
        validation.setFieldValue("phone_number", ''); // Update Formik's value
        validation.setFieldValue("countryCode",newCountry.dialCode);
        setPhoneError('Phone number is invalid, please check the provided phone number and try again')
    };

    return (
        <React.Fragment>
            <div className="auth-page-content">
                <Container>
                    <Row className="justify-content-center p-5">
                        <Col md={10} lg={8} xl={8} sm={12} xs={12}>
                            <Card className="mt-4">
                                <CardHeader className="d-flex justify-content-center ">
                                    <img alt="defualt" src={"https://clvrpay.com/static/media/logo.407eac689daf8d980d7c.png"} className="w-50"/>
                                </CardHeader>
                                <CardBody className="">
                                    {success && <div className="card">
                                        <div className="p-0 card-body">
                                            <div
                                                className="border-0 rounded-top rounded-0 m-0 d-flex align-items-center alert alert-warning fade show"
                                                role="alert">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                     stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                                     stroke-linejoin="round"
                                                     className="feather feather-alert-triangle text-warning me-2 icon-sm">
                                                    <g>
                                                        <path
                                                            d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path>
                                                        <line x1="12" y1="9" x2="12" y2="13"></line>
                                                        <line x1="12" y1="17" x2="12.01" y2="17"></line>
                                                    </g>
                                                </svg>
                                                <div className="flex-grow-1 text-truncate">
                                                    Your account under reviewing process
                                                </div>
                                            </div>
                                            <div className="align-items-end row">
                                                <div className="p-4">
                                                    We are carefully reviewing your information to ensure everything is in order. Once the process is complete, you will receive an update on your email regarding the status of your account. Thank you for your patience!
                                                </div>
                                            </div>
                                        </div>
                                    </div>}

                                    {errorMsg && <Alert color="danger">
                                        <div>{errorMsg}</div>
                                    </Alert>}

                                    {!success && <Form
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            validation.handleSubmit();
                                            return false;
                                        }}
                                        className="needs-validation" action="#">
                                      
                                        <div className="mb-3">
                                            <Label htmlFor="useremail" className="form-label">Reseller Email <span
                                                className="text-danger">*</span></Label>
                                            <Input
                                                id="email"
                                                name="email"
                                                className="form-control"
                                                placeholder="Enter email address"
                                                type="email"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.email || ""}
                                                invalid={
                                                    validation.touched.email && validation.errors.email ? true : false
                                                }
                                            />
                                            {validation.touched.email && validation.errors.email ? (
                                                <FormFeedback type="invalid">
                                                    <div>{validation.errors.email}</div>
                                                </FormFeedback>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <Label htmlFor="username" className="form-label">Username<span
                                                className="text-danger">*</span></Label>
                                            <Input
                                                name="username"
                                                type="text"
                                                placeholder="Enter Username"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.username || ""}
                                                invalid={
                                                    validation.touched.username && validation.errors.username ? true : false
                                                }
                                            />
                                            {validation.touched.username && validation.errors.username ? (
                                                <FormFeedback type="invalid">
                                                    <div>{validation.errors.username}</div>
                                                </FormFeedback>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <Label htmlFor="website" className="form-label">Trade name ( Website -
                                                Application - Social medial account ) <span
                                                    className="text-danger">*</span></Label>
                                            <Input
                                                name="website"
                                                type="text"
                                                placeholder="Enter Trade name"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.website || ""}
                                                invalid={
                                                    validation.touched.website && validation.errors.website ? true : false
                                                }
                                            />
                                            {validation.touched.website && validation.errors.website ? (
                                                <FormFeedback type="invalid">
                                                    <div>{validation.errors.website}</div>
                                                </FormFeedback>
                                            ) : null}
                                        </div>
                                        {/* Phone Number Input */}
                                        <div className="mb-3">
                                            <Label htmlFor="phone_number" className="form-label">Reseller Phone Number <span
                                                className="text-danger">*</span></Label>
                                            <IntlTelInput
                                                fieldId={"phone_number"}
                                                fieldName={"phone_number"}
                                                containerClassName="intl-tel-input  w-100"
                                                inputClassName="w-100 form-control"
                                                autoHideDialCode={false}
                                                nationalMode={true}
                                                onPhoneNumberChange={(isValid, value, country,fullNumber) =>
                                                    handlePhoneChange(isValid, value, country,fullNumber)
                                                }
                                                onSelectFlag={(currentNumber,selectedCountryData) =>
                                                    handleCountryChange(currentNumber, selectedCountryData)
                                                }
                                                value={validation.values.phone_number} // Set the value from Formik
                                            />
                                            {phoneError && <div className="invalid-feedback d-block">
                                                {phoneError}
                                            </div>}
                                        </div>
                                        <div className="mb-3">
                                            <Label htmlFor="username" className="form-label">Reseller Country <span
                                                className="text-danger">*</span></Label>
                                            <Select
                                                options={countries}
                                                id="choices-single-default"
                                                className="js-example-basic-single mb-0"
                                                name="country_id"
                                                onChange={changeCountry}
                                                onBlur={validation.handleBlur}
                                                value={countries?.find((option: any) => option.value === validation.values.country_id) || ""}
                                            />
                                            {validation.touched.country_id && validation.errors.country_id ? (
                                                <div className="invalid-feedback d-block">
                                                    {validation.errors.country_id}
                                                </div>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <Label htmlFor="userpassword" className="form-label">Password <span
                                                className="text-danger">*</span></Label>
                                            <Input
                                                name="password"
                                                type="password"
                                                placeholder="Enter Password"
                                                autoComplete={''}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.password || ""}
                                                invalid={
                                                    validation.touched.password && validation.errors.password ? true : false
                                                }
                                            />
                                            {validation.touched.password && validation.errors.password ? (
                                                <FormFeedback type="invalid">
                                                    <div>{validation.errors.password}</div>
                                                </FormFeedback>
                                            ) : null}
                                        </div>
                                        <div className="mb-2">
                                            <Label htmlFor="confirmPassword" className="form-label">Confirm
                                                Password <span className="text-danger">*</span></Label>
                                            <Input
                                                name="confirm_password"
                                                type="password"
                                                autoComplete={''}
                                                placeholder="Confirm Password"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.confirm_password || ""}
                                                invalid={
                                                    validation.touched.confirm_password && validation.errors.confirm_password ? true : false
                                                }
                                            />
                                            {validation.touched.confirm_password && validation.errors.confirm_password ? (
                                                <FormFeedback type="invalid">
                                                    <div>{validation.errors.confirm_password}</div>
                                                </FormFeedback>
                                            ) : null}
                                        </div>
                                        <div className="mt-4">
                                            <Button color="success" className="w-100" type="submit"
                                                    disabled={loader && true}>
                                                {loader &&
                                                    <Spinner size="sm" className='me-2'> Loading... </Spinner>}
                                                Sign Up
                                            </Button>
                                        </div>
                                    </Form>}
                                </CardBody>
                            </Card>
                            <div className="mt-4 text-center">
                                <p className="mb-0">Already have an account? <Link to="/login"
                                                                                   className="fw-semibold text-primary text-decoration-underline"> Signin </Link>
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Register;
