import React, { useEffect, useState } from 'react';
import {
    Container,
    Spinner,
    Pagination,
    PaginationItem,
    PaginationLink,
    Input,
    Button,
    Form,
    FormGroup,
    Label,
    Col, Row, ModalHeader, ModalBody, Modal, ModalFooter
} from "reactstrap";
import getApi from "../../../apis/get.api";
import { toast } from "react-toastify";

const ListVoucher = () => {
    document.title = "Voucher | List Vouchers";
    const [vouchers, setVouchers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [voucherCode, setVoucherCode] = useState('');
    const [showVoucherModel, setShowVoucherModel] = useState(false);
    const [selectedVoucher,setSelectedVoucher] = useState<any>(null)

    const fetchVouchers = (page : number, code : string) => {
        setLoading(true);
        let url = `/reseller/voucher/list?page=${page}`;
        if (code) {
            url += `&code=${code}`;
        }
        getApi(url)
            .then((response) => {
                const data = response.data.data;
                setVouchers(data.data);
                setCurrentPage(data.current_page);
                setLastPage(data.last_page);
            })
            .catch((error) => {
                toast.error('There was an issue while fetching the vouchers list');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchVouchers(currentPage, voucherCode);
    }, [currentPage, voucherCode]);

    const handlePageChange = (page : number) => {
        if (page !== currentPage) {
            setCurrentPage(page);
        }
    };

    const handleFilterChange = (e : any) => {
        setVoucherCode(e.target.value);
    };

    const handleFilterSubmit = (e : any) => {
        e.preventDefault();
        setCurrentPage(1);
        fetchVouchers(1, voucherCode);
    };

    function tog_center() {
        setShowVoucherModel(!showVoucherModel);
    }

    const handleSelectVoucher = (voucher : any) => {
        console.log(voucher);
        setSelectedVoucher(voucher)
        setShowVoucherModel(true)
    }

    return (
        <div className="page-content">
            <Container fluid={true}>
                <div className="rounded-3">
                    <h1 className="display-5 fw-bold">Vouchers List</h1>
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-header fs-4">
                                    In this page you will find all generated vouchers for all merchants, along with the status of each voucher
                                </div>
                                <div className="card-body">
                                    <Row className=" align-items-center">
                                        <Col lg={4} xs={12}>
                                            <Label className="visually-hidden" htmlFor="inlineFormInputGroupUsername">Username</Label>
                                            <div className="input-group">
                                                <div className="input-group-text">Voucher Code</div>
                                                <Input type="text" className="form-control" id="voucherCode" value={voucherCode} onChange={handleFilterChange} placeholder="Voucher Code" />
                                            </div>
                                        </Col>
                                        <Col lg={8} xs={12} className={"text-end mt-2"}>
                                            <button type="submit" className="btn btn-primary" onClick={handleFilterSubmit}>Search</button>
                                        </Col>
                                    </Row>
                                    <div className="table-responsive table-card mt-4">
                                        <table className="table align-middle table-nowrap table-striped-columns mb-0">
                                            <thead className="table-light">
                                            <tr className="text-center">
                                                <th scope="col" className="text-start">Voucher ID</th>
                                                <th scope="col">Voucher Merchant</th>
                                                <th scope="col">Voucher Code</th>
                                                <th scope="col">Voucher Expiration</th>
                                                <th scope="col">Voucher Amount</th>
                                                <th scope="col">Voucher Currency</th>
                                                <th scope="col">Voucher Status</th>
                                                <th scope="col" >Action</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {loading && (
                                                <tr>
                                                    <td className="text-center p-4" colSpan={7}><Spinner /></td>
                                                </tr>
                                            )}
                                            {!loading && vouchers.length > 0 && vouchers.map((voucher : any) => (
                                                <tr key={voucher.voucherId} className="text-center">
                                                    <td className="text-start"><span className="fw-medium">#{voucher.voucherId}</span></td>
                                                    <td>{voucher.generatingMerchantName}</td>
                                                    <td>{voucher.voucherCode}</td>
                                                    <td>{voucher.voucherExpiryDate}</td>
                                                    <td>{voucher.voucherAmount}</td>
                                                    <td>{voucher.voucherCurrency.toString().toUpperCase()}</td>
                                                    <td>{voucher.voucherStatus.toString().toUpperCase()}</td>
                                                    <td >
                                                        <button className="btn btn-primary btn-sm" onClick={() => {handleSelectVoucher(voucher)}}>View Information</button>
                                                    </td>
                                                </tr>
                                            ))}
                                            {!loading && vouchers.length === 0 && (
                                                <tr>
                                                    <td className="text-center p-4" colSpan={8}>No Vouchers Created Yet</td>
                                                </tr>
                                            )}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="card-footer d-flex justify-content-center mt-4 border-top-0">
                                        <Pagination>
                                            <PaginationItem disabled={currentPage === 1}>
                                                <PaginationLink previous onClick={() => handlePageChange(currentPage - 1)} />
                                            </PaginationItem>
                                            {[...Array(lastPage)].map((_, i) => (
                                                <PaginationItem key={i + 1} active={currentPage === i + 1}>
                                                    <PaginationLink onClick={() => handlePageChange(i + 1)}>
                                                        {i + 1}
                                                    </PaginationLink>
                                                </PaginationItem>
                                            ))}
                                            <PaginationItem disabled={currentPage === lastPage}>
                                                <PaginationLink next onClick={() => handlePageChange(currentPage + 1)} />
                                            </PaginationItem>
                                        </Pagination>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {selectedVoucher && <Modal isOpen={showVoucherModel} size={'xl'} toggle={() => { tog_center(); }} centered>
                    <ModalHeader className="border-4 pb-3">
                        Voucher Information
                    </ModalHeader>
                    <ModalBody className="">
                        <div className="g-3 row">
                            <div className="col-lg-6">
                                <label htmlFor="client_nameName-field" className="form-label form-label">
                                    Voucher ID
                                </label>
                                <input
                                    name="voucherId"
                                    id="voucherId"
                                    type="text"
                                    readOnly={true}
                                    className="form-control"
                                    aria-invalid="false"
                                    disabled={true}
                                    value={selectedVoucher.voucherId}
                                />
                            </div>
                            <div className="col-lg-6">
                                <label htmlFor="client_nameName-field" className="form-label form-label">
                                    Voucher Order ID
                                </label>
                                <input
                                    name="voucherOrderId"
                                    id="voucherOrderId"
                                    type="text"
                                    readOnly={true}
                                    className="form-control"
                                    aria-invalid="false"
                                    disabled={true}
                                    value={selectedVoucher.voucherOrderId}
                                />
                            </div>
                            <div className="col-lg-6">
                                <label htmlFor="client_nameName-field" className="form-label form-label">
                                    Voucher Amount
                                </label>
                                <input
                                    name="voucherAmount"
                                    id="voucherAmount"
                                    type="text"
                                    readOnly={true}
                                    className="form-control"
                                    aria-invalid="false"
                                    disabled={true}
                                    value={"$" + selectedVoucher.voucherAmount}
                                />
                            </div>
                            <div className="col-lg-6">
                                <label htmlFor="client_nameName-field" className="form-label form-label">
                                    Voucher Merchant
                                </label>
                                <input
                                    name="merchantName"
                                    id="merchantName"
                                    type="text"
                                    readOnly={true}
                                    className="form-control"
                                    aria-invalid="false"
                                    disabled={true}
                                    value={selectedVoucher.merchantName}
                                />
                            </div>
                            <div className="col-lg-6">
                                <label htmlFor="client_nameName-field" className="form-label form-label">
                                    Voucher Code
                                </label>
                                <input
                                    name="voucherCode"
                                    id="voucherCode"
                                    type="text"
                                    readOnly={true}
                                    className="form-control"
                                    aria-invalid="false"
                                    disabled={true}
                                    value={selectedVoucher.voucherCode}
                                />
                            </div>
                            <div className="col-lg-6">
                                <label htmlFor="voucherStatus-field" className="form-label form-label">
                                    Voucher Status
                                </label>
                                <input
                                    name="voucherStatus"
                                    id="voucherStatus"
                                    type="text"
                                    readOnly={true}
                                    className="form-control"
                                    aria-invalid="false"
                                    disabled={true}
                                    value={selectedVoucher.voucherStatus.toUpperCase()}
                                />
                            </div>
                            <div className="col-lg-6">
                                <label htmlFor="voucherExpiryDate-field" className="form-label form-label">
                                    Voucher Expiration Date
                                </label>
                                <input
                                    name="voucherExpiryDate"
                                    id="voucherExpiryDate"
                                    type="text"
                                    readOnly={true}
                                    className="form-control"
                                    aria-invalid="false"
                                    disabled={true}
                                    value={selectedVoucher.voucherExpiryDate}
                                />
                            </div>
                            <div className="col-lg-6">
                                <label htmlFor="voucherRedeemedDate-field" className="form-label form-label">
                                    Voucher Redeem Date
                                </label>
                                <input
                                    name="voucherRedeemedDate"
                                    id="voucherRedeemedDate"
                                    type="text"
                                    readOnly={true}
                                    className="form-control"
                                    aria-invalid="false"
                                    disabled={true}
                                    value={selectedVoucher.voucherRedeemedDate}
                                />
                            </div>
                            <div className="col-lg-6">
                                <label htmlFor="voucherDeactivatedDate-field" className="form-label form-label">
                                    Voucher Deactivate Date
                                </label>
                                <input
                                    name="voucherDeactivatedDate"
                                    id="voucherDeactivatedDate"
                                    type="text"
                                    readOnly={true}
                                    className="form-control"
                                    aria-invalid="false"
                                    disabled={true}
                                    value={selectedVoucher.voucherDeactivatedDate}
                                />
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter className="border-4 pt-2">
                        <div className="hstack gap-2 justify-content-end">
                            <button type="button" className="btn btn-danger" onClick={() => {
                                setShowVoucherModel(false)
                            }}>Close
                            </button>
                        </div>
                    </ModalFooter>
                </Modal>}
            </Container>
        </div>
    );
};

export default ListVoucher;