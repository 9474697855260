import { getSystemCurrency } from 'common/commonFunction';
import React, {useEffect, useState} from 'react';
import { Col, Row } from 'reactstrap';

const Section = (props : any) => {
    const [userName, setUserName] = useState("Admin");

    useEffect(() => {
        const authUSer : any = localStorage.getItem("reseller_authUser");
        if (authUSer) {
            const obj : any = JSON.parse(authUSer);
            setUserName(obj.username);
        }
    }, [userName]);

    return (
        <React.Fragment>
            <Row className="mb-3 pb-1">
                <Col xs={12}>
                    <div className="d-flex align-items-lg-center flex-lg-row flex-column">
                        <div className="flex-grow-1">
                            <h4 className="fs-16 mb-1">Good Morning, {userName}!</h4>
                            <p className="text-muted mb-0">Here's what's happening with your account this month.</p>
                        </div>
                        <div className="mt-3 mt-lg-0">
                            <form action="#">
                                <Row className="g-3 mb-0 align-items-center">
                                    <div className="col-sm-auto">
                                        <div className="input-group">
                                            <input
                                                className="form-control border-0 text-end dash-filter-picker shadow fs-6 fw-bold"
                                                readOnly={true}
                                                value={`${props.balance}`}
                                            />
                                            <div className="input-group-text bg-primary border-primary text-white fw-bold">
                                                <i className="ri-coins-line fs-6 p-0 pe-1"></i> {getSystemCurrency()} Balance
                                            </div>
                                        </div>
                                    </div>
                                </Row>
                            </form>
                        </div>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default Section;