import { createSlice } from "@reduxjs/toolkit";

export const initialState:any = {
    isPopupOpened : false,
    popupName : ""
};

const Popup = createSlice({
    name: 'Popup',
    initialState,
    reducers: {
        openPopup(state : any) {
            state.isPopupOpened = true;
            state.popupName = "loginPopup"
        },
        closePopup(state : any) {
            state.isPopupOpened = false;
            state.popupName = "loginPopup"
        },
        open2faPopup(state : any) {
            state.isPopupOpened = true;
            state.popupName = "2fa"
        },
        close2faPopup(state : any) {
            state.isPopupOpened = false;
            state.popupName = "2fa"
        },
      },
});

export const {
    openPopup,
    closePopup,
    open2faPopup,
    close2faPopup
} = Popup.actions
  
export default Popup.reducer;