
export const isMobileDevice = () => {
    if (typeof window !== 'undefined') {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            window.navigator.userAgent || ""
        );
    }
    return false; // Return false if window is not defined (e.g., server-side rendering)
}

export const getItemFromStorage = (item : string) => {
    if(localStorage.getItem(item)) {
        return localStorage.getItem(item)
    }
    return undefined;
}

export const getSystemCurrency = () => {
    if(localStorage.getItem('currency')) {
        return localStorage.getItem('currency')
    }
    return 'USD';
}